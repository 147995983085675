// eslint-disable-next-line no-redeclare,no-unused-vars
function addSearchBar(cont, param) {
  var view = param.view;
  var table = param.table;
  var object = param.object;
  var searchVisible = getParamSettings(param, 'searchVisible');
  var searchBar = $(
    '<div class="table-options searchBar d-flex justify-content-between w-100 searchBar"></div>'
  ).prependTo(cont);
  if (view && view.overview && view.right)
    view.right.css({marginTop: view.body.offset().top - 57});
  if (view && view.modal) view.right = view.sidebar;
  // if (view && !view.tableCacheIdents) view.tableCacheIdents = [];
  view.viewSelected = false;

  // sidebar button
  if (!view.searchBar && param.overview)
    setTimeout(function () {
      $(
        '<button class="btn btn-light"><i class="icon fa-regular fa-rectangle-portrait fa-fw"></i></button>'
      )
        .appendTo(view.buttonsRight)
        .on(click, function () {
          view.right.toggleClass('d-none');
        })
        .addTooltip(info.tooltip.showSidebar);
    }, 500);

  // full table button
  if (mobile == 'smartphone') {
    var btn = $(
      '<button class="btn btn-light"><i class="icon fa-regular fa-table fa-fw"></i></button>'
    )
      .appendTo(view.buttonsRight)
      .on(click, function () {
        view.toggleClass('view-small');
        param.ident + '_viewSmall';
        toggleLocalFlag(param.ident + '_viewSmall');
      });
    if (getLocalFlag(param.ident + '_viewSmall')) btn.trigger(click);
  }

  // table search
  var contSearch = $(
    '<div class="search me-auto" role="search"></div>'
  ).appendTo(searchBar);
  var pm = {
    placeholder: 'In Tabelle suchen',
    field: 'search',
    fieldIdent: param.ident + '_search',
    scan: true,
    save: function (value) {
      table.search = value.trim();
      table.loadData({refresh: true});
      // setObjectValue(tableCache, [view.ident, 'search'], value);
      // view.tableCacheIdents.push(view.ident);
    },
  };
  if (view && view.overview && !mobile) pm.focus = true;
  buildFormLine(contSearch, pm);

  // inline table return
  if (!param.overview) return;

  // view saver
  if (!param.picker) {
    if (view.modal) view.sidebar1 = view.sidebar;
    else if (view.sidebar1) view.sidebar1.empty();
    else view.sidebar1 = $('<div class="card"></div>').appendTo(view.right);
    var card1 = $(
      '<ul class="list-group list-group-flush pt-2 pb-2"></ul>'
    ).appendTo(view.sidebar1);
    $('<li class="list-group-item h5">Individuelle Ansichten</li>').appendTo(
      card1
    );
    var views = getParamSettings(param, 'view');
    if (!views) views = {};

    var viewSave = function (result) {
      if (!result.id && !result.title) return;

      var pm = {
        post: true,
        object: 'setting',
        send: {
          object: object,
          objectSub: param.objectSub,
          json: 1,
          key: 'view',
          value: {filter: {}},
        },
      };

      pm.send.value.tableFields = table.tableFields;
      pm.send.value.sort = table.sort;
      pm.send.value.filterFields = table.selectFilter.element.val();
      $.each(pm.send.value.filterFields, function (i, key) {
        pm.send.value.filter[key] = table.filter[key];
      });
      if (table.calendarView) pm.send.value.calendarView = table.calendarView;
      if (isset(result.all)) {
        pm.send.value.all = result.all;
        pm.send.all = result.all;
      }
      if (isset(result.default)) pm.send.value.default = result.default;
      if (isset(result.defaultAll))
        pm.send.value.defaultAll = result.defaultAll;
      if (result.id) pm.id = result.id;
      if (result.disabled) pm.send.disabled = result.disabled;
      if (result.title) pm.send.value.title = result.title;
      else if (result.id) pm.send.value.title = views[result.id].title;

      pm.callback = function (xhr) {
        var id = result.id ? result.id : xhr.id;
        if (!views[id]) views[id] = {};
        if (result.disabled) delete views[id];
        else
          $.each(pm.send.value, function (k, v) {
            views[id][k] = v;
          });
        setObjectValue(
          parameter,
          [object, 'view' + ucfirst(param.objectSub)],
          views
        );

        var values = [];
        var labels = [];
        $.each(views, function (viewId, view) {
          if (!view) return;
          labels.push(buildViewTitle(view));
          values.push(viewId);
        });
        if (!result.noUpdate) viewSaver.update(values, labels, id);
        hideElements({
          view: view.sidebar1,
          line: viewSaver.line,
          settingsNew: selectSettings,
          value: id,
        });
        localStorage.removeItem(table.identGroup + '_tableFields');
      };

      ajax(pm);
    };

    var selectSettings = $.extend(true, {}, dialogForms.viewSaver.select);
    var options = {};
    options.title = {label: 'Name der Ansicht'};
    options.default = {label: 'Standardansicht für mich', field: 'checkbox'};
    if (inArray(role, ['superadmin', 'admin', 'boss']) || user.setupUser) {
      options.all = {label: 'Für alle Mitarbeiter sichtbar', field: 'checkbox'};
      options.defaultAll = {
        label: 'Standardansicht für alle',
        field: 'checkbox',
      };
      if (checkWhere({}, selectSettings.hiddenAll)) options.all.disabled = 1;
    }
    selectSettings.view = view.sidebar1;
    selectSettings.values = [];
    selectSettings.labels = [];
    selectSettings.funcField2 = function (element) {
      var id = element.line.element.val();
      if (parseInt(views[id].all) && checkWhere({}, selectSettings.hiddenAll)) {
        showInfo(info.warning.noAuthorization, 'warning');
        return;
      }
      viewSave({id: id, noUpdate: true});
    };
    selectSettings.funcField3 = function (element) {
      var id = element.line.element.val();
      if (parseInt(views[id].all) && checkWhere({}, selectSettings.hiddenAll)) {
        showInfo(info.warning.noAuthorization, 'warning');
        return;
      }
      options.title.value = views[id].title;
      options.default.value = views[id].default;
      options.all.value = views[id].all;
      options.defaultAll.value = views[id].defaultAll;
      options.id = {value: id, hidden: 1};
      buildPromptPopup(options, viewSave, {
        button: {
          // Prettier removes quotes from this key, which causes build errors
          /* prettier-ignore */
          'Löschen': function (param) {
          /* prettier-ignore */
          viewSave({id: id, disabled: 1});
          param.view.close();
        },
        },
      });
    };
    selectSettings.funcField4 = function () {
      buildPromptPopup(options, viewSave);
    };
    selectSettings.save = function (value, element) {
      view.viewSelected = value ? true : false;
      table.filter = {};
      var setting;
      if (value)
        setting = getObjectValue(parameter, [
          object,
          'view' + ucfirst(param.objectSub),
          value,
        ]);
      if (setting) {
        table.tableFields = setting.tableFields;
        table.sort = setting.sort;
        if (setting.filter) table.filter = Object.assign({}, setting.filter);
        if (setting.filterFields)
          searchVisible = arrayMerge(searchVisible, setting.filterFields);
        if (setting.calendarView) table.calendarView = setting.calendarView;
      } else {
        table.tableFields = getParamSettings(param, 'tableFields');
        table.sort = getParamSettings(param, 'sort');
        searchVisible = getParamSettings(param, 'searchVisible');
      }
      table.filterJs = convertWhere(Object.assign(table.where, table.filter), {
        object: object,
      });
      handleFilter({clearSidebar: true, noConvert: true, handleSelect: true});

      hideElements({
        view: view.sidebar1,
        line: element.line,
        settingsNew: selectSettings,
        value: value,
      });

      // var loaded = false;
      if (param.searchFilter)
        $.each(param.searchFilter, function (key, val) {
          if (table.filter[key]) {
            val.callback(table.filter[key]);
            // loaded = true;
            return false;
          }
        });
      // if (loaded) return;

      if (param.searchFilter) table.getResourceData(null, true);
      if (table.update) table.update();
      else table.loadData({refresh: true, fromFilter: true});
    };
    if (views)
      $.each(views, function (id, view) {
        selectSettings.labels.push(buildViewTitle(view));
        selectSettings.values.push(id);
      });
    if (param.defaultView) {
      selectSettings.value = param.defaultView;
      delete param.defaultView;
    }
    var line = buildFormLine(card1, selectSettings);
    var viewSaver = line.element;
    hideElements({
      view: view.sidebar1,
      line: line,
      settingsNew: selectSettings,
      value: selectSettings.value,
    });
  }

  // table filter
  if (view.modal) view.sidebar2 = view.sidebar;
  else if (view.sidebar2) view.sidebar2.empty();
  else view.sidebar2 = $('<div class="card"></div>').appendTo(view.right);
  var card2 = $(
    '<ul class="list-group list-group-flush pt-2 pb-2"></ul>'
  ).appendTo(view.sidebar2);
  var label = 'Tabellenfilter';
  if (!param.overview && param.refObj)
    label += ' ' + fields[param.refObj][param.refKey].label;
  $('<li class="list-group-item h5">' + label + '</li>').appendTo(card2);

  // all fields
  var els = [];
  var keys = [];
  var labels = [];
  var visible = getParamSettings(param, 'form', null, null, true);
  if (visible && strpos(visible, 'form') === 0)
    visible = parameter[object][visible];
  var noFilter = getParamSettings(param, 'noFilter');
  var tableFields = getParamSettings(param, 'tableFields', null, null, true);
  $.each(fields[object], function (key, settings) {
    settings = getFieldSettings(param, key);
    if (
      settings.json ||
      !settings.label ||
      inArray(key, ['mailer', 'mailHistory']) ||
      (noFilter && inArray(key, noFilter))
    )
      return;
    else if (
      key != 'disabled' &&
      visible &&
      !inArray(key, tableFields) &&
      !inArray(':' + key, visible) &&
      !inArray(':' + key + '>', visible) &&
      !inArray('<' + key, visible) &&
      !user.admin
    )
      return;
    keys.push(key);
    labels.push(settings.label);
  });
  table.searchKeys = keys;
  sortArrays(labels, keys);

  // handle table filter in sidebar
  var handleFilter = function (param2) {
    var filter = {};

    // add pre filter fields
    if (param2.filterKeys)
      $.each(param2.filterKeys, function (i, key) {
        if (!filter[key]) filter[key] = null;
      });
    if (searchVisible) {
      $.each(searchVisible, function (i, key) {
        filter[key] = null;
      });
    }

    // add store field
    if (multiStore) filter.store = storeFilter;

    // add table filter values
    if (table.filter)
      $.each(table.filter, function (key, value) {
        filter[key] = value;
      });

    // preset filters
    // if (view.viewSelected || getParamSettings(param, 'searchPreset')) {
    //   var where = convertWhere(table.where, {simple: true});
    //   $.each(where, function (key, value) {
    //     if (key == 'disabled') return;
    //     else if (isPlainObject(value))
    //       $.each(value, function (key, value) {
    //         filter[key] = value;
    //       });
    //     else filter[key] = value;
    //     if (key == '$or') table.orSearch = true;
    //     table.filter[key] = value;
    //   });
    // }

    // build filter fields
    if (param2.clearSidebar) card2.find('[key]').remove();
    $.each(filter, function (key, val) {
      if (!fields[object][key] || (noFilter && inArray(key, noFilter))) return;
      if (param2.filterKeys && !inArray(key, param2.filterKeys)) {
        delete filter[key];
        card2.find('[key=' + key + ']').remove();
        return;
      }
      if (card2 && card2.find('[key=' + key + ']')[0]) return;
      var li = addFieldToFilter(key, {value: val});
      if (param2.focus) li.element.focus();
      if (param.searchFilter && param.searchFilter[key])
        param.searchFilter[key].element = li.element;
    });

    // handle filter select field
    if (param2.handleSelect)
      table.selectFilter.element.val(Object.keys(filter));

    // handle table data
    // if (element) {
    // 	var refresh = false;
    // 	if (!isEmptyObject(table.filter))
    // 		$.each(table.filter, function (key) {
    // 			if (!inArray(key, keys)) {
    // 				delete table.filter[key];
    // 				refresh = true;
    // 			}
    // 		});
    // 	if (refresh) table.loadData({refresh: true});
    // }
  };

  // add field
  var addFieldToFilter = function (key, param2 = {}) {
    if (card2.find('[key=' + key + ']')[0] && !param2.replace) return;
    if (view.modal) view.main.parent().addClass('width-sidebar');

    // add field
    var settings = getFieldSettings(param, key);
    settings.view = view;
    if (strpos(key, '.') !== false)
      settings = $.extend(true, {}, getRefSettings(key, object), {
        view: view,
      });
    if (settings.labelShort) settings.label = settings.labelShort;
    $.each(
      [
        'field2',
        'field3',
        'field4',
        'field5',
        'field6',
        'disable',
        'hide',
        'hidden',
        'adopt',
        'mandatory',
        'read',
        'link',
        'level',
      ],
      function (i, option) {
        if (settings[option]) delete settings[option];
        else if (settings[option + ucfirst(role)])
          delete settings[option + ucfirst(role)];
        if (settings['where' + ucfirst(option)])
          delete settings['where' + ucfirst(option)];
        if (settings[option + 'Filter'])
          settings[option] = settings[option + 'Filter'];
      }
    );
    if (settings.labelHide) delete settings.labelHide;
    if (settings.multi) delete settings.multi;
    if (settings.multiple) delete settings.multiple;
    // if (settings.default) delete settings.default;
    if (settings.entity) delete settings.entity;
    if (settings.class) delete settings.class;

    // further filters
    if (settings.field != 'checkbox') {
      settings.field6 = 'select';
      settings.noAdd = true;
      settings.noSortField6 = true;
      // settings.multiField6 = false;
      settings.placeholderField6 = '';
      settings.referenceField6 = '';
      settings.classField6 = 'overwrite';
      settings.valuesField6 = ['-', '!'];
      settings.labelsField6 = ['Feld leer', 'Feld gefüllt'];
      if (inArray(settings.field, ['number', 'decimal', 'calendar'])) {
        settings.valuesField6.push('infinive');
        settings.labelsField6.push('bis unendlich');
      } else if (!settings.labels) {
        settings.valuesField6 = arrayMerge(settings.valuesField6, [
          'like',
          'not',
        ]);
        settings.labelsField6 = arrayMerge(settings.labelsField6, [
          'Wert enthalten',
          'Wert nicht enthalten',
        ]);
      }
    }

    // primary filter
    if (param2.field) settings.field = param2.field;
    else if (settings.fieldFilter) {
      settings.field = settings.fieldFilter;
      settings.multi = true;
      if (settings.reference) settings.referenceList = 1;
    } else if (settings.sums) {
      settings.field = 'select';
      settings.multi = true;
      settings.noSort = true;
    } else if (inArray(settings.field, ['select', 'radio'])) {
      if (!settings.changeFilter) settings.multi = true;
      if (isPlainObject(settings.labels))
        settings.values = Object.keys(settings.labels);
    } else if (settings.reference == 'calendar') {
      settings.field = 'calendar';
      settings.date = true;
      settings.refField = 'start';
      delete settings.reference;
    } else if (settings.reference) {
      settings.field = 'read';
      settings.field2 = 'add';
      settings.multi = true;
    } else if (key == 'chat') {
      settings.field = 'radio';
      settings.labels = ['Neue', 'Vorhandene', 'Keine'];
      settings.values = ['unseen', '!', ''];
      settings.noSort = true;
    } else if (key == 'warning') {
      settings.field = 'select';
      settings.label = 'Ampel';
      settings.labels = ['grün', 'gelb', 'rot'];
      settings.values = ['success', 'warning', 'danger'];
      settings.noSort = true;
    } else if (settings.field == 'checkbox') {
      settings.field = 'radio';
      settings.values = ['1', '0', '*'];
      settings.labels = ['ja', 'nein', 'egal'];
      settings.noSort = true;
      delete settings.number;
    } else if (settings.json) {
      settings.field = 'input';
      settings.values = settings.valuesField = '';
      settings.placeholder = settings.placeholderField = '';
    } else if (settings.field == 'table') settings.field = 'read';
    else if (settings.decimal) settings.field = 'decimal';
    else if (settings.number) settings.field = 'number';
    else if (settings.date || settings.week) settings.field = 'calendar';
    else settings.field = 'input';

    // next fields
    if (
      inArray(settings.field, ['number', 'decimal', 'calendar']) &&
      !settings.reference &&
      !settings.field2Filter
    ) {
      settings.field2 = settings.field;
      settings.placeholder = 'von';
      settings.placeholderField2 = 'bis';
      settings.class = 'range';
    }
    if (settings.field == 'calendar') {
      settings.label += ': von-bis';
      settings.type = 'date';
    }

    var pm = {
      object: object,
      filter: true,
      view: view,
      settingsNew: settings,
      sidebar: true,
    };
    // if (
    //   view &&
    //   !param.modal &&
    //   (val = getObjectValue(tableCache, [view.ident, 'filter', key]))
    // )
    //   pm.value = val;
    if (param2.value) pm.value = param2.value;
    if (settings.searchDefault) pm.value = settings.searchDefault;
    // else if (where[key] && !str_starts_with(where[key], '<') && !str_starts_with(where[key], '>') && !isPlainObject(where[key])) pm.value = where[key];

    // resource filter field changed
    if (param.searchFilter && param.searchFilter[key])
      pm.save = function (value, element, key) {
        var and = [];
        if (value === '') delete table.filter[key];
        else if (table.filter.$or && table.filter.$or[key])
          table.filter.$or[key] = table.filterJs.$or[key] = value;
        else if (and.length) table.filter['$and'] = and;
        else table.filter[key] = table.filterJs[key] = value;

        param.searchFilter[key].callback(value);
      };
    // filter field changed
    else
      pm.save = function (value, element, key, els, li) {
        if (settings.refField) key = key + '.' + settings.refField;
        var and = [];

        // overwrite buttons
        if (element.hasClass('overwrite')) li.overwrite = value;
        if (li.overwrite) {
          value = els[0].val();
          if (li.overwrite == '-') value = '-';
          else if (li.overwrite == '!') value = '!';
          else if (li.overwrite == 'like') value = '/' + value + '/';
          else if (li.overwrite == 'not') value = '!' + value;
          else if (li.overwrite == 'infinive') value = '>=' + value;
        }

        // change other filter field
        else if (settings.changeFilter) {
          $.each(settings.changeFilter, function (key, values) {
            addFieldToFilter(key, {field: values[value], replace: true});
          });
        }

        // select filter
        else if (settings.field == 'select' && isArray(value)) {
          var values = [];
          $.each(value, function (i, val) {
            if (val == ' ') val = '';
            else if (settings.sums) val = {$like: val + '%'};
            values.push(val);
          });
          if (settings.sums) value = {$or: values};
          else value = values;
        }

        // date filters
        else if (value && settings.class == 'range') {
          var value2;
          if (els[1].is('[data-groupable]')) {
            value = els[0].val();
            value2 = els[1].val();
          }

          // days
          if (li.hasClass('days')) {
            value = 'date:' + value + ' day';
            if (value2) value2 = 'date:' + value2 + ' day';
          }

          // week
          else if (li.hasClass('week')) {
            const momentObj = moment(value);
            value = momentObj.format('YYYY-MM-DD');
            value2 = momentObj.add(7, 'day').format('YYYY-MM-DD');
          }

          // month
          else if (li.hasClass('month')) {
            const momentObj = moment(value);
            value = momentObj.format('YYYY-MM-01');
            value2 = momentObj.endOf('month').format('YYYY-MM-DD');
          }

          // year
          else if (li.hasClass('year')) {
            value2 = value + '-12-31';
            value = value + '-01-01';
          }

          // reference range
          if (value2 && settings.refField) {
            var objValue = {};
            objValue[key] = '>=' + value;
            and.push(objValue);
            var objValue2 = {};
            objValue2[key] = '<=' + value2;
            and.push(objValue2);
            delete table.filter[key];
            delete table.filterJs[key];
          }

          // normal range
          else if (value2)
            value = {
              $gte: value,
              $lte: value2,
            };
        } else if (isArray(value) && isEmptyObject(value)) value = '';

        if (value === '') delete table.filter[key];
        else if (table.filter.$or && table.filter.$or[key])
          table.filter.$or[key] = table.filterJs.$or[key] = value;
        else if (and.length) table.filter['$and'] = and;
        else table.filter[key] = table.filterJs[key] = value;

        table.loadData({refresh: true});
        // if (!param.modal)
        //   setObjectValue(tableCache, [view.ident, 'filter'], table.filter);
      };
    // if ((val = getObjectValue(tableCache, [view.ident, 'filter'])))
    //   table.filter = val;

    var li = buildFormLine(card2, pm, key);
    if (!li) return;
    if (param2.replace) card2.find('[key=' + key + ']:first').replaceWith(li);
    els = arrayMerge(els, li.els);

    // calendar select
    if (settings.field == 'calendar') {
      var lines = [li];

      pm.settingsNew = Object.assign({}, settings, {
        label: settings.label + ' Tage',
        field: 'number',
        field2: 'number',
      });
      var li2 = buildFormLine(card2, pm, key);
      li2.addClass('days');
      els = arrayMerge(els, li2.els);
      lines.push(li2);

      pm.settingsNew = Object.assign({}, settings, {
        label: settings.label + ' Woche',
        field: 'calendar',
        field2: '',
        week: true,
      });
      var li3 = buildFormLine(card2, pm, key);
      li3.addClass('week');
      els = arrayMerge(els, li3.els);
      lines.push(li3);

      pm.settingsNew = Object.assign({}, settings, {
        label: settings.label + ' Monat',
        field: 'month',
        field2: '',
        number: 0,
      });
      var li4 = buildFormLine(card2, pm, key);
      li4.addClass('month');
      els = arrayMerge(els, li4.els);
      lines.push(li4);

      pm.settingsNew = Object.assign({}, settings, {
        label: settings.label + ' Jahr',
        field: 'number',
        field2: '',
        min: 1990,
        max: moment().year(),
      });
      var li5 = buildFormLine(card2, pm, key);
      li5.addClass('year');
      els = arrayMerge(els, li5.els);
      lines.push(li5);
      linesJoin(lines);
    }
    contextMenu(li, {removeFilter: 'Filter rausnehmen'}, function (type) {
      if (type == 'removeFilter') {
        delete table.filter[key];
        table.selectFilter.rem(key);
        li.remove();
        table.loadData({refresh: true});
      }
    });

    return li;
  };

  // table filter select
  table.selectFilter = buildFormLine(card2, {
    ident: param.ident,
    field: 'select',
    label: 'Suchfelder',
    placeholder: 'Tabelle filtern',
    multi: true,
    values: keys,
    labels: labels,
    view: view,
    save: function (keys, element) {
      handleFilter({filterKeys: keys, element: element, focus: true});
      element.close();
    },
  });

  // handle initial filter
  if (param.defaultViewFilter) {
    table.filter = param.defaultViewFilter;
    table.filterJs = convertWhere(Object.assign(table.where, table.filter), {
      object: object,
    });
    delete param.defaultViewFilter;
  }
  handleFilter({handleSelect: true});

  // build or switcher
  buildFormLine(card2, {
    settingsNew: {
      label: 'Verknüpfung der Suchfelder',
      field: 'radio',
      values: ['and', 'or'],
      labels: ['UND', 'ODER'],
    },
    value: table.orSearch ? 'or' : 'and',
    save: function (value) {
      if (value == 'or') table.orSearch = true;
      else table.orSearch = false;

      // switch from or to and
      if (table.filter.$or && !table.orSearch) {
        $.each(table.filter.$or, function (key2, value2) {
          table.filter[key2] = table.filterJs[key2] = value2;
        });
        delete table.filter.$or;
      }

      table.loadData({refresh: true});
    },
  });

  // limit
  if (getParamSettings(param, 'maxAmount')) {
    // FIXME freeCont is not set a value
    // eslint-disable-next-line no-undef
    buildFormLine(freeCont, {
      placeholder: 'Max',
      field: 'number',
      save: function () {
        table.loadData({refresh: true});
      },
    });
  }

  // trash icon
  if (view && !view.modal) {
    pm = {
      ident: param.ident,
      field: 'checkbox',
      labelValue: 'Papierkorb',
      save: function (value) {
        table.filter.disabled = table.filterJs.disabled = value;
        table.loadData({refresh: true});
      },
    };
    buildFormLine(searchBar, pm).addTooltip('Papierkorb');
  }

  // dialog func
  if (view)
    view.searchField = function (key) {
      addFieldToFilter(key);
    };

  card2.formBuilt = true;
  view.searchBar = table.searchBar = true;
  return searchBar;
}

function buildViewTitle(view) {
  var title = view.title;
  var titleAdd = [];
  if (parseInt(view.all)) titleAdd.push('für alle sichtbar');
  if (parseInt(view.defaultAll)) titleAdd.push('Standard für alle');
  else if (parseInt(view.default) && view.owner == user.id)
    titleAdd.push('Standard für mich');
  if (titleAdd.length) title += ' (' + titleAdd.join(', ') + ')';
  return title;
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function linesJoin(lines) {
  var labelCheck = $('<select class="form-select"></select>').on(
    'change',
    function () {
      $.each(lines, function (i, li) {
        li.addClass('d-none');
      });
      lines[$(this).val()].removeClass('d-none').prepend(labelCheck);
      labelCheck.removeClass('double');
    }
  );
  $.each(lines, function (i, li) {
    li.addClass('d-none');
    $(
      '<option value="' + i + '">' + li.children('label').text() + '</option>'
    ).appendTo(labelCheck);
    li.children('label').remove();
  });
  labelCheck.trigger('change');
}
